import React, { useEffect } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
    Button, FormField, Heading, Icon, Link, Stack, Text,
} from 'pws-design-system/design-system';
import { navigate } from '@reach/router';
import Layout from '../../layouts/Layout';
import AuthFormView from '../../views/auth/AuthFormView';
import useApi, { Api } from '../../hooks/api/useApi';

const ForgotPasswordPage = () => {
    const { request: resetPassword, result, error, isLoading: isSubmitting } = useApi<any>((api: Api, data: any) => (
        api.routes.auth.resetPassword(data.email)
    ));

    const handleSubmit = (data: any) => {
        resetPassword({ email: data.email });
    };

    return (
        <Layout>
            <AuthFormView
                headerElement={(
                    <Heading mb={4} variant="headline">Reset Password</Heading>
                )}
                errors={error}
                onSubmit={handleSubmit}
            >
                {result.success && (
                    <Stack spacing={4}>
                        <Text variant="callout">An email was sent to the specified email address if an account exists for the user. Click on the link in the email to continue resetting your account password.</Text>
                        <Button variantColor="brand-blue" type="submit" onClick={() => navigate('/login')}>Return to Login</Button>
                    </Stack>
                )}
                {result.success === false && (
                    <Stack spacing={4}>
                        <FormField
                            id="email"
                            name="email"
                            label="Email"
                            field={{
                                variant: 'flushed',
                                rightElement: <Icon name="email" color="gray.300" />,
                            }}
                            required
                        />
                        <Text mt={-3} variant="caption2" textAlign="right" color="text.base.tertiary">
                            <Link as={GatsbyLink} to="/login">Return to Log In</Link>
                        </Text>
                        <Stack>
                            <Button variantColor="brand-blue" type="submit" isLoading={isSubmitting}>Reset Password</Button>
                        </Stack>
                    </Stack>
                )}
            </AuthFormView>
        </Layout>
    );
};

export default ForgotPasswordPage;
